var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "dialogDrag",
              rawName: "v-dialogDrag",
              value: true,
              expression: "true",
            },
          ],
          attrs: {
            visible: _vm.OpenLabelTemplate,
            title: "选择标签模板",
            "before-close": _vm.close,
            width: "1000px",
          },
        },
        [
          _c("el-scrollbar", { staticStyle: { height: "500px" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loadingLabelTemplate,
                    expression: "loadingLabelTemplate",
                  },
                ],
                staticClass: "x-w",
              },
              _vm._l(_vm.tableData, function (item) {
                return _c("div", { key: item.lableTemplateId }, [
                  _c("div", { staticClass: "templateContent" }, [
                    _c("div", { staticClass: "previewImg" }, [
                      _c(
                        "div",
                        {
                          staticClass: "printDesignPreview",
                          style: {
                            width: item.width + "mm",
                            height: item.height + "mm",
                            "background-image":
                              "url(" + item.backgroundUrl + ")",
                          },
                        },
                        _vm._l(
                          item.lableTemplateContent.labelList,
                          function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "componentWrapper",
                                style: {
                                  left: item.x + "px",
                                  top: item.y + "px",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          item.tagType != "goodsBarcode" &&
                                          item.tagType != "customText" &&
                                          item.tagType != "qrCode" &&
                                          item.tagType != "nutrient",
                                        expression:
                                          "\n                    item.tagType != 'goodsBarcode' &&\n                    item.tagType != 'customText' &&\n                    item.tagType != 'qrCode' &&\n                    item.tagType != 'nutrient'\n                  ",
                                      },
                                    ],
                                    style: {
                                      fontSize: item.fontSize + "px",
                                      fontWeight: item.bold ? "bold" : "normal",
                                      textDecoration: item.underline
                                        ? "underline"
                                        : "none",
                                    },
                                  },
                                  [_vm._v(_vm._s(item.printContent + " ###"))]
                                ),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.tagType == "nutrient",
                                        expression:
                                          "item.tagType == 'nutrient'",
                                      },
                                    ],
                                    staticClass: "y-c",
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        style: {
                                          fontSize: item.fontSize + "px",
                                          fontWeight: item.bold
                                            ? "bold"
                                            : "normal",
                                          textDecoration: item.underline
                                            ? "underline"
                                            : "none",
                                        },
                                      },
                                      [_vm._v(_vm._s(item.printContent))]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "nutrient-table" },
                                      [
                                        _c(
                                          "table",
                                          {
                                            style: {
                                              fontSize: item.fontSize + "px",
                                              fontWeight: item.bold
                                                ? "bold"
                                                : "normal",
                                              textDecoration: item.underline
                                                ? "underline"
                                                : "none",
                                            },
                                          },
                                          [
                                            _c("thead", [
                                              _c("tr", [
                                                _c("th", [
                                                  _vm._v(
                                                    _vm._s(item.labelName1)
                                                  ),
                                                ]),
                                                _c("th", [
                                                  _vm._v(
                                                    _vm._s(item.labelName2)
                                                  ),
                                                ]),
                                                _c("th", [
                                                  _vm._v(
                                                    _vm._s(item.labelName3)
                                                  ),
                                                ]),
                                              ]),
                                            ]),
                                            _c(
                                              "tbody",
                                              _vm._l(
                                                _vm.nutrients,
                                                function (nutrient, index) {
                                                  return _c(
                                                    "tr",
                                                    { key: index },
                                                    [
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            nutrient.nutrientName
                                                          )
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            nutrient.nutrientQty
                                                          )
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            nutrient.nutrientUnit
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                item.tagType == "goodsBarcode"
                                  ? _c(
                                      "div",
                                      [
                                        _c("JsBarcode", {
                                          attrs: {
                                            value: "6901028071069",
                                            displayValue: item.barcodeType != 1,
                                            width: item.barcodeWidth,
                                            height: item.barcodeHeight,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.tagType == "customText",
                                        expression:
                                          "item.tagType == 'customText'",
                                      },
                                    ],
                                    style: {
                                      fontSize: item.fontSize + "px",
                                      fontWeight: item.bold ? "bold" : "normal",
                                      textDecoration: item.underline
                                        ? "underline"
                                        : "none",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(item.customText) + " ")]
                                ),
                                _c("el-image", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: item.tagType == "qrCode",
                                      expression: "item.tagType == 'qrCode'",
                                    },
                                  ],
                                  style: {
                                    width: item.imageWidth + "mm",
                                    height: item.imageHeight + "mm",
                                  },
                                  attrs: {
                                    src:
                                      item.qrCodeUrl ||
                                      "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-05-22/8b326689866a48529f05bd1cfc50277b.png",
                                  },
                                }),
                              ],
                              1
                            )
                          }
                        ),
                        0
                      ),
                    ]),
                    _c("div", { staticClass: "marB10 x-bc" }, [
                      _c("div", { staticClass: "omit templateName" }, [
                        _vm._v(" " + _vm._s(item.lableTemplateName) + " "),
                      ]),
                      _c("div", { staticClass: "isSystem" }, [
                        _vm._v("自定义"),
                      ]),
                    ]),
                    _c("div", { staticClass: "marB10 x-bc" }, [
                      _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(item.width + "*" + item.height + "mm") +
                            " "
                        ),
                      ]),
                      _c("div", [_vm._v(_vm._s(item.createTime))]),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "copyLine" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.getConfirm(item)
                            },
                          },
                        },
                        [_vm._v("立即使用")]
                      ),
                    ],
                    1
                  ),
                ])
              }),
              0
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }