<template>
  <div>
    <el-dialog :visible="OpenLabelTemplate" title="选择标签模板" :before-close="close" v-dialogDrag="true" width="1000px">
      <!-- 整个表格 -->
      <el-scrollbar style="height: 500px;">
        <div class="x-w" v-loading="loadingLabelTemplate">
          <div v-for="item in tableData" :key="item.lableTemplateId">
            <div class="templateContent">
              <!-- 标签样式 -->
              <div class="previewImg">
                <div class="printDesignPreview" :style="{
                  width: item.width + 'mm',
                  height: item.height + 'mm',
                  'background-image': `url(${item.backgroundUrl})`
                }">
                  <div v-for="(item, index) in item.lableTemplateContent.labelList" :key="index"
                    class="componentWrapper" :style="{
                    left: item.x + 'px',
                    top: item.y + 'px'
                  }">
                    <span v-show="
                      item.tagType != 'goodsBarcode' &&
                      item.tagType != 'customText' &&
                      item.tagType != 'qrCode' &&
                      item.tagType != 'nutrient'
                    " :style="{
                      fontSize: item.fontSize + 'px',
                      fontWeight: item.bold ? 'bold' : 'normal',
                      textDecoration: item.underline ? 'underline' : 'none'
                    }">{{ item.printContent + ' ###' }}</span>
                    <div class="y-c" v-show="item.tagType == 'nutrient'">
                      <span :style="{
                      fontSize: item.fontSize + 'px',
                      fontWeight: item.bold ? 'bold' : 'normal',
                      textDecoration: item.underline ? 'underline' : 'none'
                    }">{{ item.printContent }}</span>
                      <div class="nutrient-table">
                        <table :style="{
                      fontSize: item.fontSize + 'px',
                      fontWeight: item.bold ? 'bold' : 'normal',
                      textDecoration: item.underline ? 'underline' : 'none'
                    }">
                          <thead>
                            <tr>
                              <th>{{item.labelName1}}</th>
                              <th>{{item.labelName2}}</th>
                              <th>{{item.labelName3}}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(nutrient, index) in nutrients" :key="index">
                              <td>{{ nutrient.nutrientName }}</td>
                              <td>{{ nutrient.nutrientQty }}</td>
                              <td>{{ nutrient.nutrientUnit }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <!-- 条形码 -->
                    <div v-if="item.tagType == 'goodsBarcode'">
                      <JsBarcode value="6901028071069" :displayValue="item.barcodeType != 1" :width="item.barcodeWidth"
                        :height="item.barcodeHeight" />
                    </div>
                    <!-- 自定义文字 -->
                    <span v-show="item.tagType == 'customText'" :style="{
                      fontSize: item.fontSize + 'px',
                      fontWeight: item.bold ? 'bold' : 'normal',
                      textDecoration: item.underline ? 'underline' : 'none'
                    }">
                      {{ item.customText }}
                    </span>
                    <!-- 二维码 -->
                    <el-image v-show="item.tagType == 'qrCode'" :style="{
                      width: item.imageWidth + 'mm',
                      height: item.imageHeight + 'mm'
                    }"
                      :src="item.qrCodeUrl||'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-05-22/8b326689866a48529f05bd1cfc50277b.png'" />
                  </div>
                </div>
              </div>
              <!-- 第一行 -->
              <div class="marB10 x-bc">
                <div class="omit templateName">
                  {{ item.lableTemplateName }}
                </div>
                <div class="isSystem">自定义</div>
              </div>
              <!-- 第二行 -->
              <div class="marB10 x-bc">
                <div>
                  {{ item.width + '*' + item.height + 'mm' }}
                </div>
                <div>{{ item.createTime }}</div>
              </div>
            </div>
            <!-- 第三行 -->
            <div class="copyLine">
              <el-button type="text" @click="getConfirm(item)">立即使用</el-button>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </el-dialog>
  </div>
</template>

<script>
import { labelTemplateListAPI } from '@/api/system/goods/labelTemplate' //模板
import JsBarcode from '@/views/components/jsbarcode'
export default {
  name: 'selectLabelTemplate',
  components: {
    JsBarcode
  },
  props: {
    //商品信息开关
    OpenLabelTemplate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      nutrients: [
        {
          nutrientName: '蛋白质',
          nutrientQty: '8.8克(g)',
          nutrientUnit: '15%'
        },
        {
          nutrientName: '碳水化合物',
          nutrientQty: '25.8克(g)',
          nutrientUnit: '9%'
        },
        {
          nutrientName: '脂肪',
          nutrientQty: '62.2克(g)',
          nutrientUnit: '104%'
        },
        // 添加更多营养物质...
      ],
      loadingLabelTemplate: false, //加载
      tableData: [] //会员等级
    }
  },
  async created() {
    await this.getList()
  },
  methods: {
    //渲染列表
    async getList() {
      this.loadingLabelTemplate = true
      const res = await labelTemplateListAPI()
      this.tableData = res
      this.tableData.forEach(item => {
        const newObj = JSON.parse(item.lableTemplateContent)
        this.$set(item, 'lableTemplateContent', newObj)
      })
      this.loadingLabelTemplate = false
    },
    //确定按钮
    getConfirm(item) {
      console.log('item', item)
      this.$emit('labelTemplateCom', item)
      this.close()
    },
    //关闭对话框
    close() {
      this.$emit('update:OpenLabelTemplate', false)
    }
  }
}
</script>

<style lang="scss" scoped>
//模版内容
.templateContent {
  position: relative;
  width: 290px;
  padding: 15px 15px 1px;
  background: #f7f8fa;
  border: 1px solid #dcdee0;
  border-bottom: 0px;
  box-sizing: border-box;
  border-radius: 2px;
  margin-left: 20px;
  font-size: 14px;
  //背景
  .previewImg {
    background: #fff;
    max-height: 40mm;
    max-width: 60mm;
    margin-left: 15px;
    margin-bottom: 15px;
    overflow: hidden;
    .printDesignPreview {
      // transform: scale(0.5, 0.6);
      transform-origin: left top;
      display: flex;
      background-size: cover;
      background-repeat: no-repeat;
      border: 1px solid #ebedf0;
      position: relative;
      overflow: hidden;

      .componentWrapper {
        position: absolute;
        line-height: 1;
      }
    }
  }
  //模版名字
  .templateName {
    width: calc(100% - 52px);
  }
  //是否系统
  .isSystem {
    color: rgb(23, 101, 217);
    background-color: rgb(237, 244, 255);
    border: 1px solid rgb(84, 135, 223);
    line-height: 18px;
    height: 18px;
    box-sizing: border-box;
    padding: 0 4px;
    min-width: auto;
    border-radius: 2px;
  }
}
//复制
.copyLine {
  border: 1px solid #dcdee0;
  border-top: 0px;
  margin-left: 20px;
  padding-right: 15px;
  width: 290px;
  margin-bottom: 20px;
  height: 35px;
  background: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  justify-content: space-around;
}
::v-deep .el-dialog__body {
  color: #303133;
}
.nutrient-table {
  border: 1px solid #000000; /* 外层边框 */
  table {
    width: 100%;
    border-collapse: collapse; /* 合并相邻的边框 */
  }
  th {
    border-bottom: 1px solid #000000; /* 单元格底部边框 */
  }
  td:first-child,
  th:first-child {
    text-align: left; /* 第一个td和th文本左对齐 */
  }
  td:nth-child(2),
  td:nth-child(3),
  th:nth-child(2),
  th:nth-child(3) {
    text-align: right; /* 第二个和第三个td和th文本右对齐 */
  }
  td:nth-child(2),
  th:nth-child(2) {
    padding: 0 10px;
  }
}
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}
</style>
