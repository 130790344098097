<template>
  <div>
    <el-dialog :visible="OpenLink" title="链接选择器" :before-close="close" v-dialogDrag="true" width="1300px">
      <!-- 整个表格 -->
      <div class="x-x">
        <div class="collapse marR20">
          <el-collapse v-model="activeNames">
            <el-collapse-item title="商城" name="1">
              <div class="cursorP padL30 collapseItem" :class="{ collapseActive: activeIndex === 0 }"
                @click="getCollapse(0)">
                商城页面
              </div>
              <div class="cursorP padL30 collapseItem" :class="{ collapseActive: activeIndex === 3 }"
                @click="getCollapse(3)">
                自定义页面
              </div>
            </el-collapse-item>
            <el-collapse-item title="商品" name="2">
              <div class="cursorP padL30 collapseItem" :class="{ collapseActive: activeIndex === 1 }"
                @click="getCollapse(1)">
                商品列表
              </div>
              <div class="cursorP padL30 collapseItem" :class="{ collapseActive: activeIndex === 2 }"
                @click="getCollapse(2)">
                商品分类列表
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <!-- 链接内容 -->
        <div class="linkContent flex1" v-show="this.activeIndex === 0">
          <!-- 商城页面 -->
          <div>
            <div class="fontS14c3 fontWeight7 marB20">商城页面</div>
            <ul>
              <li v-for="(item, index) in mallPageList" :key="index" class="fontS14c3"
                :class="{ checked: liShopIndex === index }" @click="getSelectLink('liShopIndex', 'mallPageList', index)"
                @mouseover="getLiMouseover('mallPageList', index)" @mouseleave="getLiMouseleave('mallPageList', index)"
                :style="{ color: item.color }">
                {{ item.name }}
              </li>
            </ul>
          </div>
          <!-- 个人中心页面 -->
          <div class="marT20">
            <div class="fontS14c3 fontWeight7 marB20">会员中心</div>
            <ul>
              <li v-for="(item, index) in personalCenterList" :key="index" class="fontS14c3"
                :class="{ checked: liMyIndex === index }"
                @click="getSelectLink('liMyIndex', 'personalCenterList', index)"
                @mouseover="getLiMouseover('personalCenterList', index)"
                @mouseleave="getLiMouseleave('personalCenterList', index)" :style="{ color: item.color }">
                {{ item.name }}
              </li>
            </ul>
          </div>
        </div>
        <!-- 商品 -->
        <div class="flex1" v-show="activeIndex === 1">
          <div class="marB20">
            <el-input size="mini" class="inputWidth" placeholder="商品编码/名称" v-model="goodsQueryParams.query" />
            <el-button class="marL10" type="primary" icon="el-icon-search" size="mini"
              @click="getGoodsList">搜索</el-button>
          </div>
          <el-table v-loading="loadingGoodsTable" tooltip-effect="dark" :data="goodsData" border max-height="400"
            height="400">
            <el-table-column label="序号" align="center" width="80" type="index" />
            <el-table-column label="商品编码" align="center" prop="goodsNo" min-width="180" />
            <el-table-column label="商品名称" align="center" prop="goodsName" min-width="180" />
            <el-table-column label="商品分类" align="center" prop="categoryName" min-width="160" />
            <el-table-column label="单位" align="center" prop="unitName" min-width="120" />
            <el-table-column label="规格" align="center" prop="goodsSpec" min-width="120" />
            <el-table-column label="价格" align="center" prop="salePrice" />
            <el-table-column fixed="right" label="操作" align="center" width="150">
              <template slot-scope="scope">
                <div class="x-fc">
                  <div class="btnSelect fontS14B" :class="{ checked: selectLinkObj.id === scope.row.goodsId }"
                    @click="handleClick('goods', scope.row)">
                    选择
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <pagination :total="goodstotal" :page.sync="goodsQueryParams.pageNum" :limit.sync="goodsQueryParams.pageSize"
            @pagination="getGoodsList" />
        </div>
        <!-- 分类 -->
        <div class="flex1" v-show="activeIndex === 2">
          <el-table :data="categoryData" border max-height="400" height="400" row-key="categoryId" :tree-props="{
              children: 'children',
              hasChildren: 'hasChildren',
            }">
            <el-table-column prop="categoryNo" label="商品分类编码" align="center" min-width="220" />
            <el-table-column prop="categoryName" label="商品分类名称" min-width="220" />
            <el-table-column fixed="right" label="操作" align="center" width="150">
              <template slot-scope="scope">
                <div class="x-fc">
                  <div class="btnSelect fontS14B" :class="{
                      checked: selectLinkObj.id === scope.row.categoryId,
                    }" @click="handleClick('class', scope.row)">
                    选择
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column />
          </el-table>
        </div>
        <!-- 自定义 -->
        <div class="flex1" v-show="activeIndex === 3">
          <div class="marB20">
            <el-input size="mini" class="inputWidth" placeholder="模版名称" v-model="goodsQueryParams.query" />
            <el-button class="marL10" type="primary" icon="el-icon-search" size="mini"
              @click="getTemplate">搜索</el-button>
          </div>
          <el-table v-loading="loadinTemplateTable" tooltip-effect="dark" :data="templateData" border max-height="400"
            height="400">
            <el-table-column label="序号" align="center" width="80" type="index" />
            <el-table-column label="模板名称" align="center" prop="templateName" min-width="180" />
            <el-table-column label="模板类型" align="center" prop="templateType" min-width="180">
              <template slot-scope="scope">
                <div>
                  {{TEMPLATE_TYPE_TEXT[scope.row.templateType]}}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="备注" align="center" prop="describe" min-width="120" />
            <el-table-column label="修改人" align="center" prop="updateBy" min-width="120" />
            <el-table-column label="修改时间" align="center" prop="updateTime" min-width="155" />
            <el-table-column fixed="right" label="操作" align="center" width="150">
              <template slot-scope="scope">
                <div class="x-fc">
                  <div class="btnSelect fontS14B" :class="{ checked: selectLinkObj.id === scope.row.templateId }"
                    @click="handleClick('template', scope.row)">
                    选择
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <pagination :total="templatetotal" :page.sync="templateQueryParams.pageNum" :limit.sync="templateQueryParams.pageSize"
            @pagination="getTemplate" />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="getConfirm" size="mini">确 定</el-button>
        <el-button @click="close" size="mini">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { templateList } from "@/api/O2OMall/config/template";
import { TEMPLATE_TYPE_TEXT } from "@/utils/constant.js";
import { goodsList } from "@/api/O2OMall/goods/list"; //商品
import { categoryList } from "@/api/O2OMall/goods/category"; //分类
export default {
  name: "selectLink",
  props: {
    //商品信息开关
    OpenLink: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      TEMPLATE_TYPE_TEXT,
      //传给小程序的值
      selectLinkObj: {
        type: 0,
        id: undefined,
        url: undefined,
        name: undefined,
      },
      //分类加载
      loadingCategoryTable: false,
      // 分类列表
      categoryData: [],
      //分类参数
      categoryQueryParams: { isRequireGoods: true },
      //商品加载
      loadingGoodsTable: false,
      // 商品总条数
      goodstotal: 0,
      // 商品列表
      goodsData: [],
      //商品参数
      goodsQueryParams: {
        pageNum: 1,
        pageSize: 15
      },
      // 自定义总条数
      templatetotal: 0,
      // 自定义列表
      templateData: [],
      //自定义参数
      templateQueryParams: {
        pageNum: 1,
        pageSize: 15,
        templateType:5,
        isOnLine: true,
      },
      //个人中心
      personalCenterList: [
        {
          name: "我的会员卡",
          url: "/subPackages/vipCard/index",
          color: "#333333",
        },
        {
          name: "领券中心",
          url: "/subPackages/couponList/couponCentre",
          color: "#333333",
        },
        {
          name: "充值中心",
          url: "/subPackages/vipCard/voucherCenter",
          color: "#333333",
        },
        {
          name: "积分商城",
          url: "/subPackages/integral/integralShopping",
          color: "#333333",
        },
        { name: "签到活动", url: "/subPackages/sigIn/index", color: "#333333" },
        // { name: '联系客服', url: '/pages/tabbar/my/index', color: '#333333' },
        {
          name: "我的评价",
          url: "/subPackages/myEvaluate/index",
          color: "#333333",
        },
        {
          name: "我的拼团",
          url: "/subPackages/groupBooking/index",
          color: "#333333",
        },
        {
          name: "收货地址",
          url: "/subPackages/shippingAddress/index",
          color: "#333333",
        },
        {
          name: "充值明细",
          url: "/subPackages/vipCard/topUpDetail",
          color: "#333333",
        },
        {
          name: "会员积分",
          url: "/subPackages/integral/index",
          color: "#333333",
        },
        {
          name: "优惠券",
          url: "/subPackages/couponList/couponList",
          color: "#333333",
        },
        {
          name: "我的订单",
          url: "/subPackages/order/myOrder?type=",
          color: "#333333",
        },
        {
          name: "待付款",
          url: "/subPackages/order/myOrder?type=1",
          color: "#333333",
        },
        {
          name: "拼团中",
          url: "/subPackages/order/myOrder?type=pintuan",
          color: "#333333",
        },
        {
          name: "待发货",
          url: "/subPackages/order/myOrder?type=4",
          color: "#333333",
        },
        {
          name: "待收货",
          url: "/subPackages/order/myOrder?type=5",
          color: "#333333",
        },
        {
          name: "待评价",
          url: "/subPackages/order/myOrder?type=-7",
          color: "#333333",
        },
        {
          name: "已取消",
          url: "/subPackages/order/myOrder?type=100",
          color: "#333333",
        },
        {
          name: "退款/售后",
          url: "/subPackages/order/myOrder?type=103",
          color: "#333333",
        },
        {
          name: "我的收藏",
          url: "/subPackages/collect/index",
          color: "#333333",
        },
        {
          name: "拼团专区",
          url: "/subPackages/activity/groupon",
          color: "#333333",
        },
        {
          name: "秒杀专区",
          url: "/subPackages/activity/seckill",
          color: "#333333",
        },
        {
          name: "plus会员",
          url: "/subPackages/plusVip/index",
          color: "#333333",
        },
        {
          name: "抽奖活动",
          url: "/subPackages/luckyDraw/index",
          color: "#333333",
        },
        {
          name: "邀请有礼",
          url: "/subPackages/inviteActivity/index",
          color: "#333333",
        },
        {
          name: "瓜分优惠券",
          url: "/subPackages/carveupActivity/index",
          color: "#333333",
        },
        {
          name: "分销中心",
          url: "/subPackages/vipDistribution/index",
          color: "#333333",
        },
        // {
        //   name: "优惠券裂变",
        //   url: "/subPackages/fissionActivity/index",
        //   color: "#333333",
        // },
      ],
      //商城页面
      mallPageList: [
        { name: "首页", url: "/pages/tabbar/home/index", color: "#333333" },
        { name: "分类", url: "/pages/tabbar/category/index", color: "#333333" },
        {
          name: "购物车",
          url: "/pages/tabbar/shoppCart/index",
          color: "#333333",
        },
        { name: "会员中心", url: "/pages/tabbar/my/index", color: "#333333" },
        {
          name: "自提",
          url: "/pages/tabbar/category/index?fetchType=1",
          color: "#333333",
        },
        {
          name: "配送",
          url: "/pages/tabbar/category/index?fetchType=2",
          color: "#333333",
        },
      ],
      //当前打开的折叠面板
      activeNames: ["1"],
      //选中折叠面板
      activeIndex: 0,
      //商城选中链接
      liShopIndex: -1,
      //个人中心选中链接
      liMyIndex: -1,
    };
  },
  watch: {
    async OpenLink() {
      if (this.OpenLink) {
        this.reset();
        await this.$nextTick();
        await this.getTemplate()
        await this.getGoodsList();
        //分类
        await this.getCategoryList();
      }
    },
  },
  methods: {
    async getTemplate() {
      this.loadinTemplateTable = true;
      const res = await templateList(this.templateQueryParams );
      this.templateData = res.rows;
      this.templatetotal = res.total;
      this.loadinTemplateTable = false;
    },
    //分类列表
    async getCategoryList() {
      this.loadingCategoryTable = true;
      const res = await categoryList(this.categoryQueryParams);
      this.categoryData = res.data;
      console.log("categoryData", this.categoryData);
      this.loadingCategoryTable = false;
    },
    // 列表转换树形列表
    recursiveToTree(data, parentId) {
      const tree = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].parentId == parentId) {
          const node = {
            parentId: data[i].parentId,
            categoryId: data[i].categoryId,
            categoryNo: data[i].categoryNo,
            categoryName: data[i].categoryName,
            imageUrl: data[i].imageUrl,
            // posterImageUrl: data[i].posterImageUrl,
            sortNo: data[i].sortNo,
            // remark: data[i].remark,
            children: this.recursiveToTree(data, data[i].categoryId),
          };

          tree.push(node);
        }
      }
      return tree;
    },
    //选择商品
    handleClick(type, item) {
      console.log("typetype", type, item);
      if (type === "goods") {
        this.selectLinkObj = {
          ...this.selectLinkObj,
          id: item.goodsId,
          name: item.goodsName,
          url: `/pages/goods/goodDetail?goodsId=${item.goodsId}`,
        };
      } else if (type === "class") {
        this.selectLinkObj = {
          ...this.selectLinkObj,
          id: item.categoryId,
          name: item.categoryName,
          url: `/subPackages/category/index?categoryId=${item.categoryId}`,
        };
      } else if (type === "template") {
        this.selectLinkObj = {
          ...this.selectLinkObj,
          id: item.templateId,
          name: item.templateName,
          url: `/subPackages/pagesCustom/index?templateId=${item.templateId}&templateName=${item.templateName}`,
        };
      }
      this.liShopIndex = -1;
      this.liMyIndex = -1;
    },
    //商品列表
    async getGoodsList() {
      this.loadingGoodsTable = true;
      const res = await goodsList({ ...this.goodsQueryParams, status: 0 });
      this.goodsData = res.rows;
      this.goodstotal = res.total;
      this.loadingGoodsTable = false;
    },
    //鼠标移入Li标签
    getLiMouseover(list, index) {
      this[list][index].color = "#1890ff";
    },
    //鼠标移出Li标签
    getLiMouseleave(list, index) {
      this[list][index].color = "#333333";
    },
    //点击li标签
    getSelectLink(indexList, list, index) {
      console.log("Sdsad", indexList);
      console.log("Sdsad", indexList);
      //跳转路径
      this.selectLinkObj.url = this[list][index].url;
      //跳转名称
      this.selectLinkObj.name = this[list][index].name;
      //选中时的焦点
      this[indexList] = index;
      //重置别的页面数据
      this.selectLinkObj.id = undefined;
      //清空商城页面或个人中心的焦点
      this[indexList === "liShopIndex" ? "liMyIndex" : "liShopIndex"] = -1;
    },
    //获折叠面板取焦点
    getCollapse(index) {
      this.selectLinkObj.type = index;
      this.activeIndex = index;
    },
    //确定按钮
    getConfirm() {
      this.$emit("selectLinkObj", this.selectLinkObj);
      this.close();
    },
    //关闭对话框
    close() {
      this.$emit("update:OpenLink", false);
    },

    //清空搜索框
    reset() {
      //重置商品参数
      this.goodsQueryParams = {
        status: 0,
        pageNum: 1,
        pageSize: 15,
        query: "",
      };
      //重置分类参数
      this.categoryQueryParams = { isRequireGoods: true };
      //传给小程序的值
      this.selectLinkObj = {
        type: 0,
        id: undefined,
        url: undefined,
        name: undefined,
      };
      //当前打开的折叠面板
      this.activeNames = ["1"];
      //选中折叠面板
      this.activeIndex = 0;
      //商城选中链接
      this.liShopIndex = -1;
      //个人中心选中链接
      this.liMyIndex = -1;
    },
  },
};
</script>

<style lang="scss" scoped>
.collapse {
  width: 158px;
  //子菜单
  .collapseItem {
    height: 48px;
    line-height: 48px;
  }
  //选中菜单
  .collapseActive {
    background-color: #e9f2fd;
    color: #1890ff;
  }

  ::v-deep .el-collapse-item__header {
    padding-left: 20px;
  }
  ::v-deep .el-collapse-item__content {
    padding-bottom: 0;
  }
}
//链接内容
.linkContent {
  //菜单样式ul
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    gap: 16px;
    display: flex;
    flex-wrap: wrap;
    li {
      list-style: none;
      margin: 0;
      padding: 11px 0;
      border-radius: 10px;
      border: 1px solid #f4f4f4;
      font-weight: normal;
      line-height: 20px;
      cursor: pointer;
      width: 120px;
      text-align: center;
    }
  }
}
//选择按钮
.btnSelect {
  margin: 0;
  padding: 11px 0;
  border-radius: 10px;
  border: 1px solid #f4f4f4;
  font-weight: normal;
  line-height: 12px;
  cursor: pointer;
  text-align: center;
  width: 80px;
}
//选中链接
.checked {
  color: #ffffff !important;
  background-color: #1890ff;
  border-color: #1890ff;
}
</style>
